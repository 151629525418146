import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
// import { email } from '@config';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--black);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--black);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Bilal Mustafa Sheikh.</h2>;
  const four = (
      <>
        <p>
          Master's Student studying Advanced Computing at{' '}
          <a href="https://www.kcl.ac.uk/" target="_blank" rel="noreferrer">
            King's College London
          </a>
          {''} and also work as a Software Engineer at{' '}
          <a href="https://cloudavian.com" target="_blank" rel="noreferrer">
            Cloudavian
          </a>
          {''}.
        </p>
      </>
  );

  const items = [one, two, four];

  return (
      <StyledHeroSection>
        {prefersReducedMotion ? (
            <>
              {items.map((item, i) => (
                  <div key={i}>{item}</div>
              ))}
            </>
        ) : (
            <TransitionGroup component={null}>
              {isMounted &&
                  items.map((item, i) => (
                      <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                        <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
                      </CSSTransition>
                  ))}
            </TransitionGroup>
        )}
      </StyledHeroSection>
  );
};

export default Hero;
